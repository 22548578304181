<template>
  <GrModal
    name="leads-automation"
    id="leads-automation"
    idModal="leads-automation"
    ref="leads-automation"
    size="md"
    title="Automação de leads"
    @shown="openModal"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Selecione um formulário para inserir os leads selecionados.</p>
          </div>
        </div>
        <b-form data-vv-name="nome">
          <b-row class="mt-3">
            <b-col cols="12">
              <b-form-group label="" label-for="method" class="form-relative">
                <div class="add-link-body">
                  <label class="mr-1">Formulário</label>
                  <router-link
                    v-if="
                      !forms.length &&
                      stops.includes('form') &&
                      verificUserLevel
                    "
                    :to="{ name: 'CampanhasLista' }"
                    class="add-link"
                    >Deseja criar um formulário?</router-link
                  >
                </div>
                <BaseSelect
                  :selectModel="selectedForm"
                  placeholder="Selecione o formulário"
                  track-by="id"
                  :array="forms"
                  :multiple="false"
                  :searchable="true"
                  :taggable="true"
                  class=""
                  :clear-on-select="false"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  :loading="loading_select"
                  @search="debounceForm($event)"
                  @callback="getForms($event)"
                  @change="selectedForm = $event"
                  :preserveSearch="true"
                  ><span slot="noResult">Nenhum formulário encontrado.</span>
                </BaseSelect>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="black" @click="onSubmit" :disabled="loading">
        Inserir leads
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </GrModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import LeadsAutomationService from "@/services/resources/LeadsAutomationService";
const serviceLeadsAutomation = LeadsAutomationService.build();

import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();

import _ from "lodash";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      loading: false,
      forms: [],
      stops: [],
      selectedForm: null,
      search: "",
      loading_select: false,
      stopForm: false,
    };
  },
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
  },
  props: ["leads", "filtros", "type_date"],
  methods: {
    debounceForm: _.debounce(function (query) {
      if (query == "") {
        this.loading_select = false;
      } else {
        this.search = query;
        this.getForms(1, query);
      }
    }, 500),
    getForms(page = 1, query = "") {
      if (this.stops.includes("form")) {
        return;
      }
      var data = {
        id: `?page=${page}&order_by=title&order=ASC&status=active&search=${query}`,
      };

      serviceForm.read(data).then((resp) => {
        this.loading_select = false;
        if (resp.data.length < resp.per_page && query == "") {
          this.stops.push("form");
        }
        this.forms = this.forms.concat(resp.data);
        this.forms = this.forms.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
      });
    },
    onSubmit() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.selectedForm) {
            this.$grToast.toast("Você precisa selecionar um formulário", {
              title: "Automação de leads",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }

          let data = {
            filters: this.filtros,
            leads: this.leads,
            form_id: this.selectedForm.id,
            order: "DESC",
            orderby: this.type_date,
            total_pagina: "20",
          };

          serviceLeadsAutomation.create(data).finally(() => {
            this.loading = false;
            this.$grToast.toast("Sua automação foi salva com sucesso", {
              title: "Automação de leads",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$emit("fetchLeads");
            this.$emit("leads-automation");
            this.$grModal.hide("leads-automation", this);
          });
        }
      });
    },
    openModal() {
      this.selectedForm = null;
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
