<template>
  <leadsList v-if="hasLoaded"
  ref="iframeLeadList"
  class="edge-spacing"
  :userData="userData" 
  :usersData="usersData" 
  :tagsData="tagsData"
  :recursosData="recursosData"
  :productsData="productsData" 
  :userLevelData="userLevelData"
  :subdomainData="usersData.tenant_subdomain"
  @returnPostMensage="returnPostMensage"
  ></leadsList>
</template>

<script>
import leadsList from "@/components/Leads/leadsLista.vue"
import iframeMixinx from "@/mixins/iframeMixin";
export default {
  mixins: [iframeMixinx],

  components: {
    leadsList,
  },
  methods: {
    updateDataIframe(dataKey, newValue){
        this.$refs.iframeLeadList.updateDataIframeLeadList(dataKey, newValue)
      },
  },
}
</script>
<style>
body:not(.modal-open):has(.edge-spacing) {
  overflow: auto !important;
}
body:not(.modal-open):has(.edge-spacing)::-webkit-scrollbar {
  width: 6px;
}
</style>
<style scoped>
.edge-spacing{
  padding: 0 40px;
}
</style>