import Rest from '@/services/Rest';

/**
 * @typedef {LeadsAutomationService}
 */
export default class LeadsAutomationService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'form/assoc'
}