<template>
  <BaseModal name="wpp-lead2" id="wpp-lead2" idModal="wpp-lead2" size="lg" title="Enviar mensagem WhatsApp">
    <div class="container">
      <div class="header">
        <div class="content">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
            <path
              d="M1.55664 6.14631H17.5566M1.55664 6.14631V15.9925C1.55664 16.3189 1.68631 16.6319 1.91712 16.8628C2.14794 17.0936 2.46099 17.2232 2.78741 17.2232H16.3259C16.6523 17.2232 16.9653 17.0936 17.1962 16.8628C17.427 16.6319 17.5566 16.3189 17.5566 15.9925V6.14631M1.55664 6.14631L3.40279 2.58939C3.60182 2.18849 3.90639 1.84945 4.28373 1.60873C4.66107 1.36801 5.0969 1.23474 5.54433 1.22324H13.5689C14.0269 1.22348 14.4757 1.35147 14.8649 1.59281C15.2541 1.83415 15.5682 2.17928 15.772 2.58939L17.5566 6.14631M9.55664 1.22324V6.14631M12.6336 11.6848H6.47972"
              stroke="#333333" stroke-width="1.71" stroke-linecap="round" />
          </svg>
          <p>Escolha o conteúdo da mensagem</p>
        </div>
        <div class="content">
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
            <path
              d="M5.47656 11.3695L2.10496 7.99786C1.94631 7.83939 1.82953 7.64397 1.76509 7.4292C1.70066 7.21441 1.69059 6.98699 1.7358 6.76735C1.7796 6.52586 1.8871 6.30045 2.04718 6.11441C2.20726 5.92837 2.41412 5.78844 2.64639 5.70911L15.8498 1.30388C16.0972 1.21194 16.3658 1.19298 16.6236 1.24923C16.8815 1.30548 17.1177 1.4346 17.3043 1.62122C17.491 1.80784 17.6201 2.04412 17.6764 2.30199C17.7326 2.55984 17.7136 2.82843 17.6217 3.07582L13.2165 16.2792C13.1424 16.5037 13.0118 16.7054 12.8372 16.8649C12.6627 17.0244 12.4501 17.1362 12.2198 17.1898C11.9909 17.2461 11.7513 17.2416 11.5247 17.1768C11.2981 17.1121 11.0923 16.9893 10.9277 16.8206L8.24521 14.1504L5.36581 15.6394L5.47656 11.3695ZM5.47656 11.3695L17.228 1.57463"
              stroke="#333333" stroke-width="1.71" stroke-linecap="round" />
          </svg>
          Revise o disparo
        </div>
      </div>
      <div >
        <CardsFileTypes ref="filestypes" :inLeadView="true" :buttonFooter="true" @receiveAutomation="receiveAutomation" class="container-data px-0 py-4" />
      </div>
    </div>

    <template v-slot:footer="{ cancel }">
      <div></div>
    </template>
  </BaseModal>
</template>
<script setup>

import CardsFileTypes from "../MessageAutomation/CardsFileTypes.vue";
import makeAutomation from "@/utils/makeAutomation.js"
import { getCurrentInstance } from "vue";

const vm = getCurrentInstance()

const props = defineProps({
  leads:{
    type: Array|String,
    default:[]
  },
  leadFilters:{
    default:{}
  }
})

const receiveAutomation = async (data, callback) =>{
  data.leads = props.leads
  data.filters = props.leadFilters
  const flag = await vm.proxy.$store.dispatch('getUniqueFlag', 'new-automation')
  await makeAutomation(data, vm.proxy, callback, 'wpp-lead2', flag)
}

</script>

<style lang="scss" scoped>
.container {
  .header {
    display: grid;
    grid-template-columns: 2fr 2fr;
    align-items: center;
    gap: 1.5rem;
  }
  .content {
    display: flex;
    gap: 10px;
    p {
      color: #333;
    }
  }
  .container-data {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    .container-item {
      overflow-y: auto;
      max-height: 300px;
      .card-item {
        display: flex;
        grid-column: 2;
        flex-direction: column;
        border-radius: 10px;
        background: #f7f7f7;
        width: 98%;
        margin-bottom: 10px;
        padding: 20px;
        .type-content {
          display: flex;
          gap: 10px;
          margin-bottom: 10px;
          p {
            color: #81858e;
          }
        }
        .item-info {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .button-edit {
            color: #2897ff;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
