export default {
  methods: {
    formatTel(phone) {
      if (!phone) return "";

      let tell = phone.replace(/[^\d]/g, "");

      if (tell.startsWith("55")) {
        tell = tell.substring(2);
      }

      if (tell.length >= 10 && !tell.startsWith("55")) {
        tell = `55${tell}`;
      }

      const url = this.isIframe ? "https://wa.me/" : "https://api.whatsapp.com/send?phone=";
      return `${url}${tell}`;
    },
  },
};
