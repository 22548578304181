<template>
  <GrModal
    name="gerence-tag"
    id="gerence-tag"
    ref="gerence-tag"
    idModal="gerence-tag"
    size="md"
    title="Automação de tags"
    @shown="openModal()"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Inserir e remover tags dos leads selecionados.</p>
          </div>
        </div>
        <b-form data-vv-name="nome">
          <b-row>
            <b-col>
              <b-form-group class="group" label="">
                <div class="add-link-body">
                  <label class="mr-1">Selecione uma tag</label>
                  <router-link
                    v-if="!tags.length && verificUserLevel && !isIframe"
                    :to="{ name: 'Tags' }"
                    class="add-link"
                    >Deseja criar uma tag?</router-link
                  >
                </div>
                <BaseSelect
                  :selectModel="tag"
                  placeholder="Selecione uma tag"
                  trackname="name"
                  track-by="id"
                  :array="tags"
                  specificType="tags"
                  :multiple="false"
                  :taggable="true"
                  class=""
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  :loading="loadingTags"
                  @callback="getTags($event)"
                  @search="debounceTags"
                  @change="tag = $event"
                  :watch="true"
                  ><span slot="noResult"
                    >Nenhuma tag encontrado.</span
                  ></BaseSelect
                >
              </b-form-group>
              <b-form-group class="grop" label="O Lead deverá:">
                <div class="d-flex" style="gap: 15px; width: 100%">
                  <BaseButton
                    :variant="action === 'in' ? 'primary' : 'outline-primary'"
                    @click="action = 'in'"
                    style="width: 100%"
                  >
                    Entrar na tag
                  </BaseButton>
                  <BaseButton
                    :variant="action === 'out' ? 'danger' : 'outline-danger'"
                    @click="action = 'out'"
                    style="width: 100%"
                  >
                    Sair da tag
                  </BaseButton>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </GrModal>
</template>
<script>
import Multiselect from "vue-multiselect";

// import LeadService from "@/services/resources/LeadService";
// const serviceLead = LeadService.build();

import TagService from "@/services/resources/TagService";
import GrModal from "../GrModal.vue";
const serviceTag = TagService.build();

export default {
  props: ["selected_lead", "filtros", "type_date","userData" ,"isIframe"],
  components: {
    Multiselect,
  },
  data() {
    return {
      action: "in",
      tag: null,
      tags: [],
      stop: false,
      loading: false,

      //tags
      loadingTags: false,
      searchTags: "",
      stops: [],
      stopTag: false,
    };
  },
  computed: {
    verificUserLevel() {
      var user = this.userData?.level ?? this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    openModal() {
      this.tag = { id: null, name: "Selecione uma Tag" };
      this.action = "in";
    },
    closeModal() {
      this.$grModal.hide('gerence-tag', this);
    },
    debounceTags: _.debounce(function (query) {
      if (query === "") {
        this.loadingTags = false;
      } else {
        this.searchTags = query;
        this.getTags(1, query);
      }
    }, 500),
    getTags(page = 1, query = "") {
      if (this.stopTag == true) {
        return;
      }

      var data = {
        id: `list?page=${page}&order_by=name&order=ASC&search=${query}`,
      };

      serviceTag
        .read(data)
        .then((resp) => {
          this.loading = false;
          if (resp.data.length < resp.per_page && query == "") {
            this.stopTag = true;
          }
          this.tags = this.tags.concat(resp.data);
          this.tags = this.tags.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .finally(() => {
          this.loadingTags = false;
        });
    },
    onSubmit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (!this.tag.id) {
            this.$grToast.toast("Selecione uma Tag", {
              title: "Automação de tags",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            return;
          }
          this.loading = true;
          if (this.action === "in") {
            let data = {
              id: `/assoc`,
              tag_id: this.tag.id,
              leads: this.selected_lead,
              filters: this.filtros,
              orderby: this.type_date,
              total_pagina: "20",
              order: "DESC",
            };

            serviceTag
              .createId(data)
              .then(() => {
                this.loading = false;
                this.$grToast.toast("Tag associada com sucesso", {
                  title: "Automação de tags",
                  variant: "info",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$emit("fetchLeads");
              })
              .catch((err) => {
                {
                  this.$grToast.toast("Erro ao associar tag", {
                    title: "Gestão de tags",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  });
                }
              })
              .finally(() => {
                this.$emit("gerence-tag");
                this.closeModal();
                this.loading = false;
              });
          }
          if (this.action === "out") {
            let data = {
              id: `/desassoc`,
              tag_id: this.tag.id,
              leads: this.selected_lead,
              filters: this.filtros,
            };

            serviceTag
              .createId(data)
              .then(() => {
                this.$grToast.toast("Tag desassociada com sucesso", {
                  title: "Automação de tags",
                  variant: "info",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
                this.$emit("gerence-tag");
                this.closeModal();
                this.$emit("fetchLeads");
              })
              .catch(() => {
                this.$grToast.toast("Erro ao desassociar tag", {
                  title: "Automação de tags",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                });
              })
              .finally(() => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
.group {
  margin-bottom: 10px !important;
}
</style>
