<template>
  <div  style="text-decoration: none; color: var(--gray01)" 
    >
    <b-row class="Table-body2" :class="getStatus(lead.status).class">
      <!-- id -->
      <b-col  style="width: 50px; align-items: center;" class="content-checkbox d-none d-flex" @click.stop="" cols="1">
        <div 
          class="check-select-lead" 
          :class="{'is-selected' : isLeadSelected}" 
          @click="$emit('updateSelectedLead', lead.id, $event.length)"
        />
        <div style="display: flex; justify-content: center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1436_593)">
              <path
                d="M14 14.76V3.5C14 2.83696 13.7366 2.20107 13.2678 1.73223C12.7989 1.26339 12.163 1 11.5 1C10.837 1 10.2011 1.26339 9.73223 1.73223C9.26339 2.20107 9 2.83696 9 3.5V14.76C8.19728 15.2963 7.58832 16.0766 7.26307 16.9856C6.93782 17.8946 6.91352 18.8841 7.19376 19.8079C7.47399 20.7317 8.04391 21.541 8.81934 22.1161C9.59476 22.6912 10.5346 23.0017 11.5 23.0017C12.4654 23.0017 13.4052 22.6912 14.1807 22.1161C14.9561 21.541 15.526 20.7317 15.8062 19.8079C16.0865 18.8841 16.0622 17.8946 15.7369 16.9856C15.4117 16.0766 14.8027 15.2963 14 14.76Z"
                :stroke="filterLeadscore"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1436_593">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {{ lead.leadscore ? lead.leadscore : 0 }}º
        </div>
      </b-col>
      <!-- lead -->
      <b-col :cols="calColum('Lead')">
        <div style="display: flex; gap: 10px; align-items: center">
          <div>
            <div class="lead-photo" v-if="photoLead">
              <img 
              :src="getLeadphoto"
              style="border-radius: 50%; width: 40px; height: 40px"
              alt="leadImage"
              />
            </div>
          <div class="lead-no-photo" v-else>
            <h3>
              {{ getMetaInitialLead }} 
            </h3>
          </div>
          </div>
          <div>
            <div v-if="lead.name" class="long-name">
                {{ lead.name }}
            </div>

            <div v-if="lead.email"
              :id="lead.email"
              class="long-email"
              v-b-tooltip="{ title: lead.email, placement: 'top', }" >
              {{ lead.email }}
            </div>

            <div v-if="lead.phone">
              <a v-if="!isIframe()"
                :href="formatTel(lead.phone)"
                target="_blank"
                class="whats-cliente"
                >
                  {{ lead.phone }}
                <img src="@/assets/icons/whats.svg" alt=""
                />
              </a>
              <div
                v-else
                @click.stop="openLeadWpp"
                style="cursor: pointer;"
                class="whats-cliente balanc-decoration">
                 {{ lead.phone }} <img src="@/assets/icons/whats.svg" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <!-- tag -->
      <b-col
        :cols="calColum('Tag')"
        v-if="validColum('Tag')"
        class="d-none d-md-block tag-column"
      >
        <span v-if="!lead.tags.length" style="font-size: 14px">
          Sem tag
        </span>
        <span :class="'tag ' + filterTag.color + '-tag'"
          :title="filterTag.name"
          v-else>
          {{ filterTag.name }}
        </span>
      </b-col>
      <!-- follow-up -->
      <b-col
        :cols="calColum('Follow-up')"
        v-if="validColum('Follow-up')"
        class="d-none d-md-block">
        <span v-if="lead.follow_up">
          {{ lead && lead.follow_up | datetime }}
        </span>
        <span style="font-size: 14px" v-else>Sem follow-up</span>
      </b-col>
      <!-- origem -->
      <b-col
        v-if="validColum('Origem')"
        :cols="calColum('Origem')"
        class="d-none d-md-block"
      >
        <div
          v-if="
            lead.origin !== 'Cadastro manual' &&
            lead.origin !== 'Cadastro pipeline' &&
            lead.origin !== 'Importacao' &&
            lead.origin !== 'LeadAds' &&
            lead.origin !== 'G Whats' &&
            lead.origin.toLowerCase() !== 'painel antigo' &&
            lead.origin !== 'new-checkout' && 
            lead.origin !== 'Club' ">
          <span v-if="lead && lead.views && lead.views.length">
            Pag:
            <span style="font-weight: 500">
              {{ filterPage(lead.views) }}
            </span>
            <br/>
          </span>
          <span v-else>
            Pag: 
            <span style="font-weight: 500">Desconhecido</span>
            <br/>
          </span>
          <span v-if="lead.forms">
            Form:
            <span style="font-weight: 500">
              {{ filterForm(lead.forms, true).title }}
            </span>
            <br/>
          </span>
          <span v-else>
            Form: 
            <span style="font-weight: 500">Desconhecido</span>
            <br/>
          </span>
          <span v-if="lead.journey">
            Jornada:
            <span style="font-weight: 500">
              {{ lead.journey }}
            </span>
            </span>
        </div>
        <span v-else> {{ returnOrigin }}</span>
      </b-col>
      <!-- entrada -->
      <b-col
        v-if="validColum('Entrada')"
        :cols="calColum('Entrada')"
        class="d-none d-md-block"
        :title="lead.created_at | datetime"
        style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; padding: 0 9px;">
        {{ lead.created_at | datetime }}
      </b-col>
      <!-- vendedor -->
      <b-col
        v-if="validColum('Vendedor')"
        :cols="calColum('Vendedor')"
        class="d-none d-md-block"
      >
      <div v-if="hasPreSeller == 'true'" style="font-weight: 400;font-size: 13px;">
        {{ filterPreSeller }}
      </div>
        {{ filterSeller }}
      </b-col>
      <!-- ultimo cadastro -->
      <!-- :cols="calColum('Último cadastro')" -->
      <b-col
        v-if="validColum('Último cadastro') && (!filters.campos_adicionais || !filters.campos_adicionais.length)"
        :cols="calColum('Último cadastro')"                                                                                                                                                                                                                                                                                                                                                                                                                                                       
        id="ultimo-cadastro"
        class="d-none d-md-block"
      >                         
        <span v-if="lead.forms" style="word-break: break-word"
          >{{ filterForm(lead.forms, show_origin_form).title }}<br/>
        </span>
        <span style="font-weight: 500">
          {{ lead.register | datetime }}</span>
      </b-col>
      <b-col
        v-else-if="validColum('Último cadastro')"
        :cols="calColum('Último cadastro')"                                                                                                                                                                                                                                                                                                                                                                                                                                                       
        id="ultimo-cadastro"
        class="adicional-lead"
      >
        <div v-if="filteredAdditionals.length > 0">
          <div v-for="meta in filteredAdditionals" :key="meta.id">
            {{ meta.meta_key }}: <span style="font-weight: 500;">{{ meta.meta_value.length > 20 ? meta.meta_value.substring(0, 20) + '...' : meta.meta_value }}</span>
          </div>
        </div>
      </b-col>

      <b-col cols="1" md="1">
        <div class="Table-body-action">
          <a
            v-if="!isIframe()"
            style="padding: 50px"
            target="_blank"
            @click.stop=""
            :href="`/${tenantSubdomain}/lead/${lead.id}`"
            ><img src="@/assets/img/icons/eye.svg"
          /></a>
          <a
            v-else
            style="padding: 50px"
            target="_blank"
            ><img src="@/assets/img/icons/eye.svg"
          /></a>
        </div>
      </b-col>
      <!-- <b-col cols="1" md="1" >
      <div class="Table-body-action"></div>
    </b-col> -->
    </b-row>
  </div>
</template>

<script>
import { getCachedData } from "@/store/indexedDB.js";
import ListarTagService from "@/services/resources/ListarTagService";

import phoneMixin from "@/mixins/phoneMixin.js"; 

const serviceListarTags = ListarTagService.build();

export default {
  mixins: [phoneMixin],
  props: ['config_seller_can_see_lead_without_seller', 'level', 'lead', 'all_leads', 'coluns_table', 'isIframe', 'hasPreSeller', 'sellers', 'filters', 'show_origin_form', 'tenantSubdomain', 'selected_lead'],
  methods: {
    getStatus(status) {
      let r = {};
      if (status) {
        switch (status.toLowerCase()) {
          case "processing": {
            r = { text: "Processando", class: "is-light-blue" };
            break;
          }
          case "authorized": {
            r = { text: "Transação autorizada", class: "is-light-blue" };
            break;
          }
          case "active": {
            r = {
              text: "Pago",
              class: "is-green",
            };
            break;
          }
          case "refunded": {
            r = { text: "Estornado", class: "is-red" };
            break;
          }

          case "waiting_payment": {
            r = { text: "Aguardando pagamento", class: "is-warning" };
            break;
          }
          case "pending_approbation": {
            r = { text: "Liberação manual", class: "is-light-blue" };
            break;
          }

          case "pending_refund":
          case "refund_pending": {
            r = { text: "Reembolso pendente", class: "is-warning" };
            break;
          }
          case "refused": {
            r = { text: "Rejeitado", class: "is-red" };
            break;
          }
          case "chargedback": {
            r = { text: "Estorno", class: "is-warning" };
            break;
          }
          case "delayed": {
            r = { text: "Em processamento", class: "is-warning" };
            break;
          }
          default: {
            r = { text: status, class: "is-black" };
            break;
          }
        }
        return r;
      } else return { text: status, class: "is-black" };
    },
    validColum(col) {
      let dataItem = this.coluns_table;
      if(col == "Último cadastro" && this.show_origin_form){
        col = "Formulário de origem";
      }
      let result = dataItem.find((x) => x.name === col);
      if (result) {
        return result.active;
      }
    },
    calColum(col) {
      let dataItem = this.coluns_table;
      let result = dataItem.filter((x) => x.active === true);
      if (result.length === 6) {
        if (col === "Lead" || col === "Último cadastro" || col === "Formulário de origem") {
          return 2;
        } else if (col === "Origem") {
          return 2;
        }
        return 1;
      } else if (result.length === 5) {
        if (
          dataItem.find((obj) => obj.name === "Origem").active == false ||
          dataItem.find((obj) => (obj.name == "Último cadastro" || obj.name == "Formulário de origem")).active == false
        ) {
          if (col === "Lead") {
            return 3;
          } else if (
            col === "Origem" ||
            col === "Último cadastro"  || col === "Formulário de origem" ||
            col === "Tag"
          ) {
            return 2;
          }
          return 1;
        }
        if (col === "Lead") {
          return 3;
        } else if (col === "Origem" || col === "Último cadastro" || col === "Formulário de origem") {
          return 2;
        }
        return 1;
      } else if (result.length === 4) {
        return 2;
      } else if (result.length === 3) {
        if (col === "Lead") {
          return 4;
        } else {
          return 2;
        }
      } else if (result.length === 2) {
        if (col === "Lead") {
          return 4;
        }
        return 3;
      } else if (result.length === 1) {
        return 5;
      } else if (result.length === 0) {
        if (col === "Lead") {
          return 10;
        }
      } else {
        if (col === "Lead") {
          return;
        } else if (col === "Origem") {
          return 3;
        } else {
          return 2;
        }
      }
    },
    filterForm(forms, first_form) {
      let index = first_form ? 0 : forms.length - 1;
      if (index > -1) {
        let result = forms[index];
        if (result) {
          return result;
        }
        return { title: "Desconhecido", created_at: "" };
      }
      return { title: "Desconhecido", created_at: "" };
    },
    filterPage(views) {
      // return id_page;
      if (views.length) {
        return views[0].title;
      }
      return "Desconhecido";
    },
    
    openLeadWpp() {
      let data = {
        type: 'openLead',
        href: this.formatTel(this.lead.phone)
      };

      this.$emit('returnPostMensage', data);
    },
  },
  computed: {
    filteredAdditionals() {
      return this.lead?.metas.filter(meta => meta.meta_key !== 'photo-lead') || [];
    },
    isLeadSelected() {
      return this.selected_lead.includes(this.lead.id)
    },
    validConfigdLeads() {
      return this.lead.filter((item) => {
        return (
          item.user_id !== 0 ||
          this.level !== "seller" ||
          this.config_seller_can_see_lead_without_seller == "true"
        );
      });
    },
    filterTag() {
      let index = this.lead.tags.length - 1;
      if (index > -1) {
        let result = this.lead.tags[index];
        return result ? result : { name: "Sem tag", color: "" };
      }
      return "Sem tag";
    },
    filterSeller() {
      let seller = this.sellers;

      let result = seller.find((x) => x.id === this.lead.user_id);
      if (!result) {
        return "Sem vendedor";
      }
      return result.first_name && result.first_name.length ? result.first_name : result.email.split("@")[0];
    },
    returnOrigin() {
      switch (this.lead.origin) {
        case "Cadastro manual":
          return "Cadastro manual";
        case "Importacao":
          return "Importação";
        case "Cadastro pipeline":
          return "Cadastro CRM";
        case "LeadAds":
          return "LeadAds";
        case "G Whats":
          return "G Whats";
        case "new-checkout":
          return "Greenn Checkout";
        default:
          return this.lead.origin;
      }
    },

    getMetaInitialLead() {
      let leadName = this.lead.name
      if (leadName) {
        let name = leadName.split(" ")[0];
        let last_name = leadName.split(" ")[1] ?? undefined;
        if (!name && !last_name) {
          return '';
        }
        if (!last_name) {
          return name.substring(0, 2);
        }
        return name[0] + last_name[0];
      }
      return;
    },
    
    photoLead() {
      return this.lead.metas?.some(meta => meta.meta_key === 'photo-lead');
    },
    
    getLeadphoto() {
      let photoMeta = this.lead?.metas.find(meta => meta.meta_key === 'photo-lead');
      
      return photoMeta ? photoMeta.meta_value : '';
    },

    filterPreSeller(){
      let preSeller = this.sellers;
      let result = preSeller.find((x) => x.id === this.lead.pre_user_id);
      if (!result) {
        return "Sem Pré-vendedor";
      }
      return result.first_name && result.first_name.length ? result.first_name : result.email.split("@")[0];
    },
    filterLeadscore() {
      if (!this.lead.leadscore) {
        return "#cecece";
      } else if (this.lead.leadscore <= 0) {
        return `#43ff64d9`;
      } else if (this.lead.leadscore <= 10) {
        return `#3FA9DE`;
      } else if (this.lead.leadscore <= 20) {
        return `#3DA1DF`;
      } else if (this.lead.leadscore <= 30) {
        return `#9ABD5C`;
      } else if (this.lead.leadscore <= 40) {
        return `#37A0A4`;
      } else if (this.lead.leadscore <= 50) {
        return `#A373A8`;
      } else if (this.lead.leadscore <= 60) {
        return `#B19A86`;
      } else if (this.lead.leadscore <= 70) {
        return `#EFC033`;
      } else if (this.lead.leadscore <= 80) {
        return `#E2845B`;
      } else if (this.lead.leadscore <= 90) {
        return `#D82984`;
      } else if (this.lead.leadscore <= 100) {
        return `#D34F68`;
      } else if (this.lead.leadscore <= 120) {
        return `#008E7B`;
      } else if (this.lead.leadscore > 120) {
        return `var(--greenn)`;
      } else {
        return "#cecece";
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.content-checkbox{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.check-select-lead {
  height: 16px;
  width: 16px;
  border-radius: 5px;
  border: 1px solid var(--white-dark);
  margin-right: 10px;
  cursor: pointer;
  &.is-selected {
    background-color: var(--primary-500);
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    background-position: center;
    border-color: var(--primary-500);
  }
}
.lead-no-photo h3 {
  text-align: center; 
  color: var(--greenn); 
  font-size: 17px;
  text-transform: uppercase;
  margin: 0 !important;
}
.lead-no-photo {
  margin: 0 !important; 
  border: var(--greenn) ,1px, solid;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: var(--greenn-transparent);
  display: flex;
  align-items: center;
  justify-content: center;
}
.Table-body {
  &:hover {
    transform: scale(1.01);
  }
}

.Table-body2 {
  // padding: 15px;
  display: flex;
  align-items: center;
}

.Table-body2::before {
  left: 0px;
}
.whats-cliente {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 13px;
  color: var(--greenn);
}
.balanc-decoration:hover{
  text-decoration: underline !important;
}
.whats-cliente svg {
  cursor: pointer;
}
.tag-column {
  display: grid !important;
  place-content: start !important;
  padding-left: 0px !important;
}
.tag {
  padding: 5px;
  border-radius: 10px;
  display: block;
  width: 100px !important;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  white-space: nowrap;
}

.info-tag {
  background: var(--blue2);
  color: var(--bluee);
}

.danger-tag {
  background: var(--red2);
  color: var(--red);
}

.warning-tag {
  background: var(--orange2);
  color: var(--orange);
}

.primary-tag {
  background: var(--greenn2);
  color: var(--greenn);
}

.success-tag {
  background: var(--blue2);
  color: var(--bluee);
  font-size: 14px;
}

.default-tag {
  background: rgba(170, 170, 170, 0.301);
  font-size: 14px;
}

.adicional-lead {
  font-weight: 600;
  max-height: 100px;
  max-width: 90%;
  overflow-y: auto;
  overflow-x: hidden;
}

.long-name {
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.long-email{
  max-width: 130px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 1350px) {
  .zoom {
    zoom: 0.85;
  }

  .qualif {
    margin-left: -10px;
  }

  .checkbox-all {
    top: 3px;
  }
}

@media (max-width: 1180px) {
  .zoom {
    zoom: 0.7;
  }
}

@media (max-width: 1000px) {
  .zoom {
    zoom: 0.6;
  }
}
</style>