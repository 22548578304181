<template>
  <GrModal
    name="journey-automation"
    id="journey-automation"
    idModal="journey-automation"
    ref="journey-automation"
    size="lg"
    title="Automação de jornada"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Inserir a jornada que o lead deve percorrer.</p>
          </div>
        </div>
        <b-form data-vv-name="nome">
          <b-row>
            <b-col>
              <b-form-group label="Selecione uma jornada">
                <div class="d-flex" style="gap: 15px; width: 100%">
                  <BaseButton
                    :variant="jornada === 'V' ? 'primary' : 'outline-primary'"
                    @click="jornada = 'V'"
                    style="width: 100%"
                  >
                    Vendas
                  </BaseButton>
                  <BaseButton
                    :variant="jornada === 'M' ? 'info2' : 'outline-info'"
                    @click="jornada = 'M'"
                    style="width: 100%"
                  >
                    Marketing
                  </BaseButton>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </GrModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import JornadaService from "@/services/resources/JornadaService";
const serviceJornada = JornadaService.build();

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      jornada: null,
      loading: false,
    };
  },
  computed: {
    sellers() {
      return this.$store.getters.seller;
    },
  },
  props: ["leads", "selected_lead", "filtros", "type_date"],
  methods: {
    marketingPop() {},
    onSubmit() {
      this.loading = true;
      // console.log(this.jornada, "wenewnenw");
      if (this.jornada === null) {
        this.$grToast.toast("Selecione uma jornada!", {
          title: "Automação de jornada",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });

        this.loading = false;
        return;
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          let data = {
            leads: this.leads,
            order: "DESC",
            journey: this.jornada,
            orderby: this.type_date,
            filters: this.filtros,
          };
          serviceJornada
            .create(data)
            .then((rest) => {})
            .catch((err) => {
              // console.log(err.response);
            })
            .finally(() => {
              this.loading = false;
              this.$grToast.toast("Jornada salva com sucesso", {
                title: "Automação de jornada",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.jornada = null;

              this.$emit("journey-automation");
              this.$grModal.hide("journey-automation", this);
            });
        }
      });
    },
    openModal() {
      (this.nome = ""),
        (this.email = ""),
        (this.telefone = ""),
        (this.formulario = null);
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
