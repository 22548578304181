import Rest from "@/services/Rest";

/**
 * @typedef {AdicionaisService}
 */
export default class AdicionaisService extends Rest {
  /**
   * @type {String}
   */
  static resource = "lead/metas";
}
